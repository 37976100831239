import { Injectable } from '@angular/core';
import { TranslateService } from 'src/app/shared/translate.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private translateService: TranslateService
  ){}

 /** 
  * Method that returns the placeholder of the field 
  * according to the name and the language 
 **/
  public getPlaceHolder (fieldName: string) : string {  
    const fields = {
      'nombrecentro': this.translateService.data.NOMBRECENTRO,
      'nombreresponsable': this.translateService.data.NOMBRERESPONSABLE,
      'curso': this.translateService.data.CURSO,
      'tipocentro': this.translateService.data.TIPOCENTRO,
      'email': this.translateService.data.EMAIL,
      'codigoPostal': this.translateService.data.CODIGOPOSTALR,
      'tipoDocumento': this.translateService.data.TIPODOCUMENTO,
      'documento': this.translateService.data.DOCUMENTO,
      'CIFoDNI': this.translateService.data.DOCUMENTO,
      'telefono': this.translateService.data.TELEFONO,
      'telefonomovil': this.translateService.data.TELEFONOMOVIL,
      'direccion': this.translateService.data.DIRECCION,
      'ciudad': this.translateService.data.CIUDAD,
      'notas': this.translateService.data.NOTAS,
      'notasinternas': this.translateService.data.NOTASINTERNAS,
      'pais': this.translateService.data.PAIS,
      'Nombrecontacto': this.translateService.data.NOMBRECONTACTO,
      'apellidos': this.translateService.data.APELLIDO1,
      'Apellido2Contacto': this.translateService.data.APELLIDO2,
      'sexo': this.translateService.data.SEXO,
      'nacionalidad': this.translateService.data.NACIONALIDAD,
      'codigopais': this.translateService.data.CODIGOPAIS,
      'edad': this.translateService.data.EDAD,
      'fechanacimiento': this.translateService.data.FECHANACIMIENTO,
      'emailconfirmacion': this.translateService.data.EMAILCONFIRMACION,
      'Privacidad': this.translateService.data.CAMPO_PRIVACIDAD,
      'recaptchaReactive': this.translateService.data.CAMPO_RECAPTCHA,
      'fakePublicity': this.translateService.data.FAKE_PUBLICITY,
      'provincia': this.translateService.data.PROVINCIA,
      'nombre': this.translateService.data.NOMBRE,
      'Otro': this.translateService.data.OTRO,
    }

    return fields[fieldName] || fieldName;    
  }

}