import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { AppConfiguration } from "src/app/app.configuration";
import * as moment from "moment";
import { ModalService } from "src/app/shared/modal.service";
import { InitService } from "src/app/core/init/init.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "src/app/shared/translate.service";
import { Subject } from "rxjs";
import { ConfigurationService } from "src/app/core/config/configuration.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	private ngUnsubscribe = new Subject();
	year = moment().year();

	proyecto = this.config.getProyecto();

  cookiesExterno;

	cargado;
	translation: any;
	internalpol: any;

	constructor(
		private config: AppConfiguration,
		private modalService: ModalService,
		private initService: InitService,
		private _sanitizer: DomSanitizer,
		private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.translateService
			.use(this.translateService.getLocale(), this.config.getProyecto())
			.then((i) => {
				this.internalpol = i["CONFIG_POLITICAS_INTERNAS"];
			});
    
    this.configurationService.configuration$.subscribe((config) => {
      if (
        config &&
        config != undefined &&
        config != null &&
        config.datosProyecto
      ) {
        this.cookiesExterno =
          this.configurationService.getCaracteristicasAdicionalesProyecto().CookiesExterno;

       /*  console.log({
          cookiesExternoFooter: this.cookiesExterno
        }) */
      }
    });
	
	}

	ngAfterContentInit() {}

	public get footerLink(): SafeHtml {
		return this._sanitizer.bypassSecurityTrustHtml(
			this.translateService.data.FOOTER
		);
	}

	abrirCookies() {
		this.modalService.open("cookies");
		this.initService.setModalCookiesValue(true);
	}

	openModalCookies() {
		/* 
    this.initService.modalCookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cargado => {
 
      this.cargado = cargado;
      if(this.cargado){
        this.initService.setModalCookiesValue(false);
        this.initService.setAceptaCookieGoolge(false);
        this.modalService.open("cookies")
        this.cargado=false

      }
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }); */
		this.modalService.open("cookies", true);
	}

   //MAPFRE
  getFooterText(){
    const footerText = this.translateService.data.FOOTER;
    return this.sanitizer.bypassSecurityTrustHtml(footerText);
  }


	openModalPoliticas() {
		this.modalService.open("politicas", true);
	}
}
