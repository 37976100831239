import { Injectable } from '@angular/core';
import { AppConfiguration } from '../app.configuration';
import { InitService } from '../core/init/init.service';
import { environment } from '../../environments/environment';
declare var gtag;
declare var dataLayer;
declare var stepView;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsEventsServiceService {
  cookies;
  isDev = !environment.production;

  constructor(
    private initService: InitService, 
    private config: AppConfiguration) {}

  public emitEvent(Type: string,
    Name: string,
    Values: string = null) {
    if(this.isDev) {
      return;
    }  
    if (!sessionStorage.getItem('cookies')) {
      this.cargarCookies();
      this.getCookies(this.cookies);
    }
    gtag('config', this.config.getGoogleId());
    if (Values) {
      gtag(Type, Name, Values);
    } else {
      gtag(Type, Name);
    }

  }
  public dataLayerEcommerce(data) {
    try {
      if (!sessionStorage.getItem('cookies')) {
        this.cargarCookies();
        this.getCookies(this.cookies);
      }

      dataLayer.push({ ecommerce: null });
      this.sendDataLayer(data);

    } catch (e) {
    }

  }

  public sendDataLayer(data) {
    dataLayer.push(data);
  }

  public cargarCookies() {
    if (!sessionStorage.getItem('cookies')) {
      this.initService.cookies$.subscribe(cookies => {
        this.cookies = cookies;
        sessionStorage.setItem('cookies', JSON.stringify(this.cookies));
      });
      this.cookies = this.initService.cookiesValue;
    } else {
      this.getCookies(JSON.parse(sessionStorage.getItem('cookies')));
    }
  }
  public getCookies(cookies) {
    cookies.forEach(cookie => {
      if (cookie.aceptar) {
        if (cookie.chScript !== '') {
          const scriptToString = JSON.stringify(cookie.chScript);
          const script = JSON.parse(JSON.parse(scriptToString));

          let scriptF;
          script.forEach((s: any) => { 
            if(s.noscript) {
              scriptF = document.createElement('noscript');
              if (s.text) {
                scriptF['text'] = s.text;
              }
            } else {
              scriptF = document.createElement('script');
              if (s.async) {
                scriptF.async = s.async;
              }
              if (s.src) {
                scriptF.src = s.src;
              }
              if (s.type) {
                scriptF.type = s.type;
              }
              if (s.text) {
                scriptF.text = s.text;
              }
            }
            document.head.appendChild(scriptF);
          });
        }
      }
    });
  }

  public callStepView(paso) {
    if (this.config.getProyecto() == "MAPFRE") {
      stepView(paso)
    }
  }

}
