export const environment = {
  "production": true,
  "console":false,
  "API_URL": "https://apipre.apiacpos.com/2_5_0_bridge/",
  "numSerie": "WEBAN$A$l06$A$GULAR$A$AM3[jC)",
  "chClaveEmpresa": "FMCMP_Madrid",
  "chNombreProyecto": "VENTAONLINE",
  "chEntornoConexion": "PREPRODUCCION",
  "chOrigenConexion": "INTERNET",
  "chPasarelaEntorno": "PREPRODUCCION",
  "pasarelaRedsys": "PREPRODUCCION",
  "pasarelaPhoneSell": "PHONE_M_PRO", 
  "pasarelaEntornoPhoneSell": "CONSULTA_PRO_PS",
  "pasarelaPaypal": "PAYPAL_PRO",
  "URL_PDF": "http://grecpruebas.iacpos.com/grec-api/tickets/",
  "URLOK": "http://ticketsmadrid.fundacioncristinamasaveu.iacpospre.com/thankyou",
  "URLKO": "http://ticketsmadrid.fundacioncristinamasaveu.iacpospre.com/thankko",
  "URL": "http://ticketsmadrid.fundacioncristinamasaveu.iacpospre.com",
  "siteKey" : "6LfXS2IUAAAAADr2WUPQDzAnTEbSQzE1Jxh0Zi0a"
}