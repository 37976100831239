import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/internal/operators";
import { Subject } from "rxjs/internal/Subject";
import { take } from "rxjs/operators";
import { ProductosService } from "src/app/business/productos.service";
import { PaymentService } from "src/app/core/payment/payment.service";
import { VentaService } from "src/app/core/services/venta.service";
import { TranslateService } from "src/app/shared/translate.service";

@Component({
	selector: "app-descuentos",
	templateUrl: "./descuentos.component.html",
	styleUrls: ["./descuentos.component.scss"],
})
export class DescuentosComponent implements OnInit, OnDestroy {
	@Input() datosForm: FormGroup;
	@Input() compradorForm: FormGroup;
	@Input() tieneUser: boolean;

	private ngUnsubscribe = new Subject();
	optionsDrop = ["NIF", "NIE"]; // Yaribel 20210820 VSTS 8424
	descuentoForm: FormGroup;
	descuentoAdminForm: FormGroup;
	valeForm: FormGroup;
	descuentoClienteAsociadoForm: FormGroup; // Yaribel 20210820 VSTS 8424
	descuentoLabel;
	descuentoClienteLabel; // Yaribel 20210820 VSTS 8424
	descuentoValeLabel = false;
	img;
	check = false;
	mostrarDescuento;
	mostrarVale;
	mostrarDescuentoAdmin;
	mostrarDescuentoCliente; // Yaribel 20210820 VSTS 8424
	tiposDescuento = ["Porcentaje", "Importe"];
	totalDescuento;
	descuento;
	descuentoComisiones;
	mensaje;
	wait: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private ventaService: VentaService,
		private paymentService: PaymentService,
		private productosService: ProductosService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.descuentoForm = this.formBuilder.group({
			descuento: ["", [Validators.required]],
		});

		this.descuentoClienteAsociadoForm = this.formBuilder.group({
			NumeroDocumento: [""],
			TipoDocumento: ["NIF"],
		});

		this.valeForm = this.formBuilder.group({
			codigo: [""],
		});
		this.descuentoAdminForm = this.formBuilder.group({
			cantidad: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
			tipo: ["", [Validators.required]],
		});
		this.paymentService.mostrarDescuento$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((mostrar) => (this.mostrarDescuento = mostrar));
		this.paymentService.mostrarDescuentoClienteAsociado$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((mostrar) => (this.mostrarDescuentoCliente = mostrar)); // Yaribel 20210914 VSTS 8424
		this.paymentService.mostrarDescuentoAdmin$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((mostrar) => (this.mostrarDescuentoAdmin = mostrar));
		this.paymentService.mostrarValePromocional$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((mostrar) => (this.mostrarVale = mostrar));
		this.paymentService.hayDescuento$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((descuento) => {
				this.descuentoValeLabel = descuento;
				this.descuentoLabel = descuento;
			});
		this.ventaService.discount = false;
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	get d() {
		return this.descuentoForm.controls;
	}

	get h() {
		return this.descuentoAdminForm.controls;
	}

	get v() {
		return this.valeForm.controls;
	}
	// << Yaribel 20210820 VSTS 8424
	get u() {
		return this.descuentoClienteAsociadoForm.controls;
	}
	// VSTS 8424 >>
	precioTotal() {
		return this.ventaService.carritoValue.calcularPrecioTotal();
	}

	comprobarCupon() {
		this.descuentoValeLabel = false;
		this.ventaService.setDescuentoPer(undefined);
		this.mensaje = null;
		this.ventaService.setDescuento(this.descuentoForm.value);
		const cliente = {
			...this.compradorForm.value,
			...this.ventaService.clienteValue,
		};

		const newClient = this.ventaService.getClientParsed(this.compradorForm);

		console.log({
			action: "comprobarCupon",
			cliente,
		});
		this.ventaService.setCliente(cliente);
		this.productosService
			.checkDescuento()
			.pipe(take(1))
			.subscribe((res) => {
				this.descuentoLabel = true;
				if (res.DatosResult === null) {
					this.ventaService.setDescuento(undefined);
					this.ventaService.setDescuentoComisiones(undefined);
					sessionStorage.removeItem("descuento");
					this.mensaje = res.Mensajes[0].DescripcionMensaje;
					this.img = "assets/img/KO.jpg";
				} else {
					if (res.DatosResult.chTipoDescuento === "PR") {
						this.totalDescuento =
							this.precioTotal() - parseFloat(res.DatosResult.dDescuento);
						this.descuento = parseFloat(res.DatosResult.dDescuento) + "€";
						sessionStorage.setItem(
							"descuento",
							JSON.stringify({ descuento: this.descuento })
						);
						this.mensaje =
							this.translateService.data.CUPON +
							res.DatosResult.dDescuento.replace(".", ",") +
							"0€";
					} else {
						this.totalDescuento =
							this.precioTotal() -
							this.precioTotal() *
								(parseFloat(res.DatosResult.dDescuento) / 100);
						this.descuento = parseInt(res.DatosResult.dDescuento, 10) + "%";
						sessionStorage.setItem(
							"descuento",
							JSON.stringify({ descuento: this.descuento })
						);
						this.mensaje =
							this.translateService.data.CUPON +
							parseInt(res.DatosResult.dDescuento, 10) +
							"%";
					}
					this.img = "assets/img/ok.jpg";
					this.ventaService.setDescuentoTotales({
						descuento: this.descuento,
						totalDescuento: this.totalDescuento,
					});
				}
			});
	}

	comprobarCuponAdmin() {
		this.descuentoValeLabel = false;
		this.ventaService.setDescuento(undefined);
		this.ventaService.setDescuentoComisiones(undefined)
		this.mensaje = null;
		this.ventaService.setDescuentoPer(this.descuentoAdminForm.value);
		const cliente = {
			...this.compradorForm.value,
			...this.ventaService.clienteValue,
		};

		const newClient = this.ventaService.getClientParsed(this.compradorForm);
		this.ventaService.setCliente(cliente);
		this.productosService
			.checkDescuentoAdmin()
			.pipe(take(1))
			.subscribe((res) => {
				this.descuentoLabel = true;
				if (res.DatosResult === null) {
					this.ventaService.setDescuentoPer(undefined);
					sessionStorage.removeItem("descuento");
					this.mensaje = res.Mensajes[0].DescripcionMensaje;
					this.img = "assets/img/KO.jpg";
				} else {
					this.ventaService.setDescuentoPersonalizado(
						res.DatosResult.ImporteDescuento
					);
					this.totalDescuento =
						this.precioTotal() - parseFloat(res.DatosResult.ImporteDescuento);
					this.descuento =
						res.DatosResult.ImporteDescuento.toString().replace(".", ",") + "€";
					sessionStorage.setItem(
						"descuento",
						JSON.stringify({ descuento: this.descuento })
					);
					this.mensaje =
						this.translateService.data.CUPON +
						res.DatosResult.ImporteDescuento.toString().replace(".", ",") +
						"€";
					this.img = "assets/img/ok.jpg";
					this.ventaService.setDescuentoTotales({
						descuento: this.descuento,
						totalDescuento: this.totalDescuento,
					});
				}
			});
	}

	comprobarValePromocional() {
		this.descuentoLabel = false;
		this.ventaService.setDescuentoPer(undefined);
		this.mensaje = null;
		this.ventaService.setDescuentoCod(this.valeForm.value);
		const cliente = {
			...this.compradorForm.value,
			...this.ventaService.clienteValue,
		};

		const newClient = this.ventaService.getClientParsed(this.compradorForm);
		console.log({
			action: "comprobarValePromocional",
			cliente,
		});
		this.ventaService.setCliente(cliente);
		this.productosService
			.checkValePromocional()
			.pipe(take(1))
			.subscribe((res) => {
				this.descuentoValeLabel = true;
				if (!res.DatosResult) {
					this.ventaService.setDescuentoCod(undefined);
					sessionStorage.removeItem("descuento");
					this.mensaje = res.Mensajes[0].DescripcionMensaje;
					this.img = "assets/img/KO.jpg";
				} else {
					this.descuento = this.ventaService.calculoDescuento(
						res.DatosResult.DictTarifasProductos
					);

					this.ventaService.setDescuento({
						Descuento: this.descuento,
					});

					this.totalDescuento = this.precioTotal() - this.descuento;
					sessionStorage.setItem(
						"descuento",
						JSON.stringify({ descuento: this.descuento })
					);

					this.mensaje = this.translateService.data.CODIGOPROMOCIONALOK;
					this.img = "assets/img/ok.jpg";
					this.ventaService.setDescuentoTotales({
						descuento: this.descuento,
						totalDescuento: this.totalDescuento,
					});
				}
			});
	}

	getErrorTranslate(error_:any){
		
		let error = error_ || '';

		if (error.startsWith('L')) {    
			error="L";
		}

		switch (error) {
			case "0":
				return this.translateService.data.errorNoModificar;
				break;
			case "-1":
				return this.translateService.data.noConexionIacpos;
				break;
			case "RN03":
				return this.translateService.data.indicarRegistro;
				break;
			case "01":
				return this.translateService.data.errorServidor;
				break;
			case "E0":
				return this.translateService.data.errorNoHaModificado;
				break;
			case "E2":
				return this.translateService.data.mismoNombreCliente;
				break;
			case "E3":
				return this.translateService.data.emailExistente;
				break;
			case "E4":
				return this.translateService.data.clienteExistente;
				break;
			case "E5":
				return this.translateService.data.cifExistente;
				break;
			case "E6":
				return this.translateService.data.noCodigoCliente;
				break;
			case "C1":
				return this.translateService.data.contrasenaNumerosLetras;
				break;
			case "C2":
				return this.translateService.data.contrasenaNumerosLetrasCaracteresEspeciales;
				break;
			case "C3":
				return this.translateService.data.contrasenaNumerosLetrasCaracteresEspecialesMayusculasMinusculas;
				break;
			case "C4":
				return this.translateService.data.tokenCaducadoNoExiste;
				break;

			default:
				const hasTranslate = this.translateService.data[error];
				
				if (hasTranslate && hasTranslate.length > 0) {
					return hasTranslate;
				}

				return this.translateService.data.noControlado;
				break;  
		}
	}

	comprobarDNIClienteAsociado(doc: string) {
		this.descuentoClienteLabel = false;
		this.ventaService.setDescuentoClienteAsociado(undefined);
		this.mensaje = null;
		const valForm = this.descuentoClienteAsociadoForm.value;

		if (valForm.NumeroDocumento.length === 0) {
			this.ventaService.discount = false;
			return;
		}

		if (valForm.TipoDocumento && valForm.NumeroDocumento.length > 0) {
			this.wait = true;
			this.ventaService.discount = true;
			this.productosService
				.checkCodigoClienteAsociado(
					valForm.TipoDocumento,
					valForm.NumeroDocumento.trim().toUpperCase()
				)
				.pipe(take(1))
				.subscribe((res) => {
					this.ventaService.setDescuentoClienteAsociadoCod(
						this.descuentoClienteAsociadoForm.value
					);
					const cliente = {
						...this.compradorForm.value,
						...this.ventaService.clienteValue,
					};

					const newClient = this.ventaService.getClientParsed(
						this.compradorForm
					);
					console.log({
						action: "comprobarDNIClienteAsociado",
						cliente,
					});
					this.ventaService.setCliente(cliente);
					this.descuentoClienteLabel = true;
					this.wait = false;

					if (!res || !res.DatosResult) {
						this.ventaService.setDescuentoClienteAsociadoCod(undefined);
						sessionStorage.removeItem("descuento");
						
						this.img = "assets/img/KO.jpg";
						this.mensaje = this.getErrorTranslate(res.Mensajes[0].CodigoMensaje || '');

					} else {
						if (
							res.Mensajes &&
							res.Mensajes.length > 0
						) {
							this.ventaService.setDescuentoClienteAsociadoCod(undefined);
							sessionStorage.removeItem("descuento");
							this.mensaje = this.getErrorTranslate(res.Mensajes[0].CodigoMensaje || '');
							this.img = "assets/img/KO.jpg";
						} else {
							this.ventaService.discount = false;
							this.descuento = this.ventaService.calculoDescuento(
								res.DatosResult.DictTarifasProductos,
								"ClienteAsociado"
							);
							this.ventaService.setDescuentoClienteAsociado(this.descuento);
							this.totalDescuento = this.precioTotal() - this.descuento;
							//this.ventaService.setCarrito(this.totalDescuento)
							sessionStorage.setItem(
								"descuento",
								JSON.stringify({ descuento: this.descuento })
							);
							this.mensaje = this.translateService.data.CODIGOPROMOCIONALOK;
							this.img = "assets/img/ok.jpg";
							this.ventaService.setDescuentoTotales({
								descuento: this.descuento,
								totalDescuento: this.totalDescuento,
							});
						}
					}
				});
		}
	}

	public changeDocument = (doc: string): void => {
		this.descuentoClienteAsociadoForm.controls["NumeroDocumento"].setValue("");
	};
}
