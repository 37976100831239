import { HttpClient } from '@angular/common/http';
import { Component, Directive, ElementRef, InjectionToken, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AppConfiguration } from 'src/app/app.configuration';
import { UserService } from 'src/app/user/user.service';
import { ModalService } from '../modal.service';
import { TranslateService } from '../translate.service';



@Component({
  selector: 'app-modal-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.scss']/* ,
  providers: [
    // Provide a value to be used by Angular's dependency injection
    // machanism to pass 
    TranslateService,
   {provide: 'TRANS_PARAM' , useValue: 'politicas'}
  ] */
})

/* @Directive({
  selector: 'app-modal-politicas',
  providers: [TranslateService]
})
 */
export class PoliticasComponent implements OnInit, OnDestroy {  
  @Input() id: string;

  private element: any;
  content;
  previousUrl: string;

  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private router: Router,
    private translate: TranslateService,
    private config: AppConfiguration,
    private userService: UserService,
    public _httpClient: HttpClient,
    private _sanitizer: DomSanitizer
    ) {
    this.element = el.nativeElement;
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      //('prev:', event.url);
      this.previousUrl = event.url;
    });
   }


  ngOnInit(): void {
    const modal = this;
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    this.modalService.add(this);

    this.loadContent(localStorage.getItem("lastlang"));
    this.userService.setlang.subscribe(lang => {
      this.loadContent(lang);
    })
  }

  loadContent(lang) {
    if(this.config.getProyecto() === 'MAPFRE' || this.config.getProyecto() === 'GRANADACARD' || this.config.getProyecto() === 'ARGONNE')
      return;
    //let path = 'assets/html' + {fileName} + '.html';
    let path = 'assets/html/politica_compra_'+lang+'.html';
    this._httpClient.get(path, {responseType: "text"}).subscribe(
    data => {
    //now you have the file content in 'data'
    this.content = this._sanitizer.bypassSecurityTrustHtml(data);
    },
    error => { return; }
    );
  }

  open(flag?): void {

    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');

  }
  ngOnDestroy(): void {

    this.element.remove();
  }
  close(): void {
    //this.router.navigate(['/tienda/productos']);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
}
