import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Paginator } from '../../../classes/paginator/paginator';
import { DialogoPopUp } from 'src/app/shared/components/alert/dialog/popup.service';
import { AlertService } from 'src/app/shared/services/alerts/alert/alert.service';
import { SnackbarInfoService } from 'src/app/shared/services/alerts/snackbar-info/snackbar-info.service';
/* import { ConectarApiService } from 'src/app/shared/services/api/connection/conectar-api.service'; */
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { table } from 'src/app/shared/classes/tables/table';
import { FilterService } from 'src/app/shared/services/filter/filter.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { ModalService } from 'src/app/shared/modal.service';
import { getTraduccionMatPag } from 'src/app/shared/components/tables/table/trad-paginator-intl';
import { UserService } from 'src/app/user/user.service';
import { PaymentService } from 'src/app/core/payment/payment.service';
import { InitService } from 'src/app/core/init/init.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import * as moment from 'moment';
import { ConfigurationService } from 'src/app/core/config/configuration.service';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: getTraduccionMatPag() }]
})
export class TableComponent implements OnInit, AfterViewInit {

  private ngUnsubscribe = new Subject();
  @Input() tableName: string;

  //@ContentChild(MatPaginator, { read: MatPaginator }) paginator: MatPaginator;
  //@ContentChild(MatSort, { read: MatSort }) sort: MatSort;


  @ViewChild('paginatortable', { read: MatPaginator, static: false }) paginator: MatPaginator;
  @ViewChild('sorttable', { read: MatSort, static: false }) sort: MatSort;

  @Input() parent_pkId: string;

  public dataSource: MatTableDataSource<any>;
  public columnsToDisplay: string[] = [];

  b_checkParams=false;


  public translation: any;
  public table: table ={"tableName":null,"maxItems":10,"filterByApi":false,"columns":null};
  public showTable = false;
  // Panel
  public customanpanel: any;
  public customanPkId: any;
  public customanMode: any;
  public paginatorLength = 0;
  public paginatorSize = 0;
  public tableLoaded = false;
  public panelLoaded = false;
  debajauser = false;

  fromAdmon: Subscription;
  fromAdm = false;

  AplazadoEsVenta='';
  
  constructor(
    private translator: TranslateService,
    private filterService: FilterService,
    public cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private alertaService: AlertService,
    private snackvar_service: SnackbarInfoService,
    private modalService: ModalService,
    private userService: UserService,
    private paymentService: PaymentService,
    private initService:InitService,
    private configurationService: ConfigurationService,
    private sesionService:SesionService
  ) {

    this.translation = this.translator.data;

  }

  ngOnDestroy() {
     this.filterService.setTable({"tableName":null,"maxItems":10,"columns":null})
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    /* this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator; */

    this.paginatorManage();
    this.sortManage()

    
    
    this.filterService.tableLoaded.subscribe(ev => {
      this.tableLoaded = ev;
    });

    if (this.sort) {
      this.sort.sortables.forEach((value, key) => {
      //this.sort.deregister(value)
      })

    }

  }

  ngOnInit(): void {
    this.initializeTranslate();

    //ver si el usuario está de baja
    this.userService.currentUser$.subscribe(user => {
      if (user && user.DeBaja=="1") { 
        this.debajauser = true;
      }
    })

    this.getTable();
    this.getData();
    this.reloadTable();
    
    this.closepanel();
    this.updatePanel();

    if (this.configurationService.configurationValue && JSON.parse(this.configurationService.configurationValue.datosProyecto.CaracteristicasAdicionales).CaracteristicasAdicionales.AplazadoEsVenta) 
      this.AplazadoEsVenta = JSON.parse(this.configurationService.configurationValue.datosProyecto.CaracteristicasAdicionales).CaracteristicasAdicionales.AplazadoEsVenta;

    this.fromAdmon = this.initService.fromAdmon$.subscribe(adm => this.fromAdm = adm);

    
  }


 /*  ngAfterContentInit() {

    this.paginatorManage();
    this.sortManage()
  } */

  paginatorManage() {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = this.translator.data["elemntospagina"];
      this.paginator.page.subscribe(page => {
        const paginator = new Paginator(page.pageIndex + 1, page.pageSize, '', 'ASC');
        if (this.dataSource.sort.direction != '') {
          paginator.orderby = this.table.columns.find(find => this.dataSource.sort.active == find.name).BBDD;
          paginator.ordertype = this.dataSource.sort.direction.toUpperCase();
        } else {
          paginator.orderby = this.table.columns[1].BBDD;

        }
        this.loadData(paginator);
      });

    }
  }
  sortManage() {
    if (this.sort) {
      this.sort.sortChange.subscribe(sort => {
        const paginator = new Paginator(1, this.paginator.pageSize, '', 'ASC');
        if (this.dataSource.sort.direction != '') {
          paginator.orderby = this.table.columns.find(find => this.dataSource.sort.active == find.name).BBDD;
          paginator.ordertype = this.dataSource.sort.direction.toUpperCase();
        } else {
          paginator.orderby = this.table.columns[1].BBDD;

        }

        this.loadData(paginator);
      });

    }
  }

  actionManage(action, row) {
    switch (action) {
      case 'duplicar':
        this.duplicateRow(row);
        break;
      case 'borrar':
        this.borrar(row);
        break;
        case 'masinfo':          
          if (this.tableName=="operaciones") this.modalService.moreInfo("moreInformation",row.Localizador);
        break;
        case 'pdf':
          if (this.tableName=="operaciones") this.filterService.getPDF(this.tableName,row);
        break;
        case 'email':
          if (this.tableName=="operaciones") this.filterService.sendEmail(this.tableName,row);
        break;
        case 'anular':
          if (this.tableName=="operaciones")this.anular(row)
        break;  
        case 'pagar':
          this.pagar(row);
        break;  
        case 'modificar':
          this.modificar(row);
        break;  
      default:
        break;
    }
  }

  borrar(row: string): void {

    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '300px',
      data: {
        titulo: this.translation.PagarOperacion, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        this.manageDel(this.tableName, row);
      }
    });
  }

  pagar(row): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo:  this.translator.data["PAGAR_REGISTRO"], texto: this.translator.data["EstaSeguroQueDeseaPagarElRegistro"],
        eleccion: "Patatas", ok: this.translator.data["CONFIRMAR"], ko: this.translator.data["CANCELAR"]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
        this.paymentService.modificarNumeroPedido('', row.Localizador, 1, currentuser.NombreCliente,currentuser.Contrasena).subscribe(productos => {

          if (productos["DatosResult"] != null) {
            this.paymentService.pasarelaPago(productos["DatosResult"].MensajeRespuesta,(row.ImporteTotal*100).toString()).subscribe(pasarela => {
            });
          } else {
            this.snackvar_service.openSnackBar(productos["Mensajes"][0].DescripcionMensaje, '', 'red-snackbar');
          }
          //this.dataSource = productos.ProductosCompra;
    
        });
        //this.manageDel(this.tableName, row);
        //a pagar...
      }
    });
  }
  anular(row){
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translator.data["ANULAR_REGISTRO"], texto: this.translator.data["EstaSeguroQueDeseaCancelarElRegistro"],
        eleccion: "Patatas", ok: this.translator.data["CONFIRMAR"], ko: this.translator.data["CANCELAR"]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
        this.userService.anularOperacion(row.Localizador, currentuser.NombreCliente,currentuser.Contrasena).subscribe(gpdf => {
          this.reloadTable();
        });
      }
    });
  }

  modificar(row): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translator.data["Modificar_Operacion"], texto: this.translator.data["EstaSeguroQueDeseaModificarElRegistro"],
        eleccion: "Patatas", ok: this.translator.data["CONFIRMAR"], ko: this.translator.data["CANCELAR"]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        let currentuser=JSON.parse(sessionStorage.getItem('currentUser'));
        let lastlang=localStorage.getItem('lastlang');
        this.paymentService.modificarNumeroPedido('', row.Localizador, 1, currentuser.NombreCliente,currentuser.Contrasena).subscribe(productos => {

          if (productos["DatosResult"] != null) {
            let jsonurl = '{"numpedmod":"'+productos["DatosResult"].MensajeRespuesta+'","aforo":"1","u":"'+currentuser.NombreCliente+'","p":"'+currentuser.Contrasena+'","lg":"'+lastlang+'"}';
            jsonurl = this.initService.funcionEncript(jsonurl);
            this.paymentService.modificarReserva(jsonurl);
          } else {
            this.snackvar_service.openSnackBar(productos["Mensajes"][0].DescripcionMensaje, '', 'red-snackbar');
          }
          //this.dataSource = productos.ProductosCompra;
    
        });
        //this.manageDel(this.tableName, row);
        //a pagar...
      }
    });
  }


  manageDel(tableName, row) {
    switch (tableName) {
      case 'perfilUsuarios':
        this.deloperaciones(row.pkId);
        break;

      default:
        break;
    }
  }

  /*
Descargar PDF: Si Anulada: “NO” y Vendida: "NO"
Enviar email: Si Anulada: “NO” y Vendida: "NO"
Anular: Si Anulada: “NO”, Pagada: “NO” y Vendida: "NO"

  */

caduca(unidades,tipo,hora,fechavisita,horavisita) {
  let caduca = false;
  //this.sesionService.pasarAminutos()
  var today = new Date();
  var a = moment(today);

  switch (tipo) {
    case "dias":
      let tiempoendias =  unidades;
      let diasparasesion = this.sesionService.getDiastoSession(fechavisita,horavisita); //dias sin incluir hoy ni el dia de la visita
      diasparasesion++; //icluimos el dia de la visita
    
      
      if (tiempoendias>diasparasesion) {
        caduca =true;
      } else if (tiempoendias==diasparasesion) {
        if (hora && hora!="") {
          
          //let a = moment().hours();
         
          var lahora = moment(moment(today).format("DD/MM/YYYY") + " " + hora, 'DD/MM/YYYY HH:mm');
          var ahora = moment(moment(today).format("HH:mm"), "HH:mm");
          
          if (lahora.isBefore(a)) {
            caduca=true;
          }
        } 
      }

      break;
    case "horas":
      let tiempoenminutos =  this.sesionService.pasarAminutos("horas",unidades);
      let minutosparasesion = this.sesionService.getMinutestoSession(fechavisita,horavisita);

      if (minutosparasesion<=tiempoenminutos)
        caduca = true; 

      break;
    default:
      break;

  }

  return caduca;
}

disableAction(action,row, AplazadoEsVenta) {
  let disable = true;
  let formadepago_cliente = this.userService.getUser().TipoPagoId; //obtenemos la forma de pago del cliente


  //FechaVisita
  //HoraVisita
  let Botones = JSON.parse(row.Botones);
  //let Cad= this.getCaducidad();
  let Cad = Botones;

  let datoscaducidad = {
    CodigosFormaPago:[],
    Anular:{unidades:0, tipo:"", hora:""},
    Modificar:{unidades:0, tipo:"", hora:""},
    Pagar:{unidades:0, tipo:"", hora:""}
  };
  let codigo=null;
  
  if (Cad && Cad.Caducidad) 
  Cad.Caducidad.FormasdePago.forEach(fp => {
    if (codigo = fp.CodigosFormaPago.find(cod => cod == parseInt(formadepago_cliente)) ) {
      datoscaducidad = fp;
    }
      
  });

  /*
MIS COMPRAS: Poder modificar y/o pagar con FP = IN una reserva si tbReservas.bAnulada = 0, tbReservas.bPagada = 0, tbReservas.bVendida = 0 y tbReservas.chFOP = DP si se considera que es una reserva y no una venta en firme (tbProyectos.chJsonConfiguración -> AplazadoEsVenta = 0)

Si, creo que ahora en Operaciones, se visualizan como pagadas las operaciones que tengan el estado que comenta la tarea, eso tiene que cambiar según la configuración del proyecto, si el proyecto pone que AplazadoEsVenta = 0, se tiene que poder pagar la reserva, se tiene que mostrar el botón pagar/modificar/anular (según la forma de pago), y si AplazadoEsVenta es 1 o no existe el atributo, se comporta como hasta ahora, 

dice, poder modificar y/o pagar con forma de pago IN (Tarjeta) si una reserva.....

Si aplazado (chFOP = DP) y AplazadoEsVenta = 0, se tiene que poder pagar (FP = In (ternet))
Si aplazado (chFOP = DP) y AplazadoEsVenta = 0, se tiene que poder modificar (FP = In (ternet))
  */

  switch (this.tableName) {
    case 'operaciones':
      //ANULAR
      if (action=="anular" && (row.Anulada=="No" && row.Pagada=="No" && row.Vendida=="No" && !this.debajauser)) {
        if (this.fromAdm) {
          if (!Cad.Caducidad?.ModoAdmon?.Anular?.unidades || Cad.Caducidad?.ModoAdmon?.Anular?.unidades==0) //si no hay unidades o unidades es 0 
              disable = false; //El boton estará activo
            else { //validar con los datos de caducidad para ModoAdmon y fecha/hora visita/espectáculo
              if (this.caduca(Cad?.Caducidad?.ModoAdmon?.Anular?.unidades, Cad.Caducidad?.ModoAdmon?.Anular?.tipo, Cad.Caducidad?.ModoAdmon?.Anular?.hora, row.FechaVisita, row.HoraVisita))
                  disable = true; //con los datos enviados la reserva a caducado
                else 
                  disable = false; //no caduca
            }
        } else {
          //no administración
          // si se cumplen las condiciones
            if (this.caduca(datoscaducidad.Anular.unidades, datoscaducidad.Anular.tipo, datoscaducidad.Anular.hora, row.FechaVisita, row.HoraVisita))
              {
                disable = true; //con los datos enviados la reserva a caducado
              }
            else {
              disable = false; //no caduca
            }

        }
      }
      //PAGAR
      if (action=="pagar" && (row.Anulada=="No" && row.Pagada=="No" && !this.debajauser)) {  
        if (this.fromAdm) {
          if (!Cad.Caducidad?.ModoAdmon?.Anular?.unidades || Cad.Caducidad?.ModoAdmon?.Anular?.unidades==0) //si no hay unidades o unidades es 0 
            disable = false; //El boton estará activo
          else { //validar con los datos de caducidad para ModoAdmon y fecha/hora visita/espectáculo
              if (this.caduca(Cad?.Caducidad?.ModoAdmon?.Anular?.unidades, Cad.Caducidad?.ModoAdmon?.Anular?.tipo, Cad.Caducidad?.ModoAdmon?.Anular?.hora, row.FechaVisita, row.HoraVisita))
                disable = true; //con los datos enviados la reserva a caducado
              else 
                disable = false; //no caduca
          }
        } else {
          //no administración
          // si se cumplen las condiciones
          if (this.caduca(datoscaducidad.Pagar.unidades, datoscaducidad.Pagar.tipo, datoscaducidad.Pagar.hora, row.FechaVisita, row.HoraVisita))
          {
            disable = true; //con los datos enviados la reserva a caducado
          }
          else {
            disable = false; //no caduca
          }
        }
      }
      //MODIFICAR
      if (action=="modificar" && (row.Anulada=="No" && !this.debajauser && row.Vendida=="No")) {
        if (this.fromAdm) {
          if (!Cad.Caducidad?.ModoAdmon?.Anular?.unidades || Cad.Caducidad?.ModoAdmon?.Anular?.unidades==0) //si no hay unidades o unidades es 0 
            disable = false; //El boton estará activo
          else { //validar con los datos de caducidad para ModoAdmon y fecha/hora visita/espectáculo
            if (this.caduca(Cad?.Caducidad?.ModoAdmon?.Anular?.unidades, Cad.Caducidad?.ModoAdmon?.Anular?.tipo, Cad.Caducidad?.ModoAdmon?.Anular?.hora, row.FechaVisita, row.HoraVisita))
                disable = true; //con los datos enviados la reserva a caducado
              else 
                disable = false; //no caduca
          }
        } else {
          //no administración
          // si se cumplen las condiciones
          if (row.VentaAdmon=="1") {
            disable = true;
          } else {
              if (this.caduca(datoscaducidad.Modificar.unidades, datoscaducidad.Modificar.tipo, datoscaducidad.Modificar.hora, row.FechaVisita, row.HoraVisita))
              {
                disable = true; //con los datos enviados la reserva a caducado
              }
              else {
                disable = false; //no caduca
              }
          }
        }
      }
      //OTROS BOTONES
      if (action=="email" && row.Anulada=="No" && row.Vendida=="No") disable =  false;
      if (action=="pdf" && row.Anulada=="No" && row.Vendida=="No") disable =  false;
      if (action=="masinfo") disable = false;
      break;

    default:
      break;
  }
  return disable;
}

getCaducidad() {
  return {Caducidad: {
    ModoAdmon: {
      Modificar: {
        unidades: 0,
        tipo: "",
        hora: ""
      },
      Pagar: {
        unidades: 0,
        tipo: "",
        hora: ""
      },
      Anular: {
        unidades: 0,
        tipo: "",
        hora: ""
      }
    },
    FormasdePago:
      [
        {
          CodigosFormaPago: [0,1,5],
          Modificar: {
            unidades: 2,
            tipo: "dias",
            hora: "14:00"
          },
          Pagar: {
            unidades: 2,
            tipo: "dias",
            hora: "14:00"
          },
          Anular: {
            unidades: 2,
            tipo: "dias",
            hora: "14:00"
          }
        },
        {
          CodigosFormaPago: [2,3,4,6,8],
          Modificar: {
            unidades: 1,
            tipo: "horas",
            hora: ""
          },
          Pagar: {
            unidades: 2,
            tipo: "horas",
            hora: ""
          },
          Anular: {
            unidades: 2,
            tipo: "horas",
            hora: ""
          }
        }
      ]
  }}
 
}
/*   disableActions(action,row) {
    let disabled=false;
    switch (this.tableName) {
      case 'operaciones':
        if (action=="anular" && (row.Anulada!="No" || row.Pagada!="No" || this.debajauser)) return true;
        if (action=="pagar" && (row.Anulada!="No" || row.Pagada!="No" || this.debajauser)) return true;
        if (action=="modificar" && (row.Anulada!="No" || row.Pagada!="No" || this.debajauser)) return true;
        if (action=="email" && row.Anulada!="No") return true;
        if (action=="pdf" && row.Anulada!="No") return true;
        break;

      default:
        break;
    }
    return false;
  } */

  showActions(action,row) {
    let show=true;
    switch (this.tableName) {
      case 'operaciones':
        if (action=="pagar" && (row.Anulada!="No" || row.Pagada!="No" || this.debajauser)) show  = false;
        if (action=="modificar" && (row.Anulada!="No" || this.debajauser)) show  = false;
        break;

      default:
        break;
    }
    return show;
  }

  hayTraduccion(termino) {
    return this.translator.data[termino] ? true : false;
  }

  deloperaciones(row) {
/*     this.categoriasService.delCategoria(row.pkId).pipe(first())
      .subscribe(data => {
        // this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
        if (data.DatosResult != null) {
          this.snackvar_service.openSnackBar(this.translation.DatosBorradosCorrectamente, '', 'green-snackbar');
          this.reloadTable();
        }
        else {
          this.snackvar_service.openSnackBar(data.Mensajes[0].DescripcionMensaje, '', 'red-snackbar');
        }
      },
        error => { this.httpErrorService.identificarErrores(error); }
      ); */
  }


/*   checkParams(){
    this.route.queryParams.subscribe(params => {
      let param = params["pkId"];
     //let dataJson = '{"nombre":"jordi","contrasena":"Jordijordi2!","pkid":1,"ruta":"/Clientes/Cliente"}';
     
      if(param){
        this.filterService.filterByPkId(param)
        this.dataSource.data.map(data=>{
          if(data.pkId== param && !this.b_checkParams){
            this.editRow(data)
            this.b_checkParams=true;
            
            
          }
        })
      }
  
    });
  } */

  getData() {

    this.filterService.dataTableFilter$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data != "") {
        this.dataSource = data;
        this.dataSource.sort = this.sort;
/*         if(!this.b_checkParams){
          this.checkParams()
        } */
        if (this.dataSource["page"] == 1) {
          if (this.paginator) this.paginator.firstPage()
        }
        //this.dataSource.paginator = this.paginator; 
        //this.paginator.length=100
        // this.paginatorLength=100
        // this.paginatorSize = 5

        if (this.paginator == undefined) {
          this.paginator = data.paginator;
        }


        if (this.paginator)  this.paginator.length = data.totalResultados;
        if (this.paginator)  this.paginator.pageSize = data.pagelements;
        this.cdRef.detectChanges();



      }
      // this.paginatorLength=data.totalResultados



    });
  }
  loadData(paginator) {
    if (this.parent_pkId!="")
      this.filterService.manageGetDataDB(this.tableName, paginator, this.parent_pkId); 
    else 
     this.filterService.manageGetDataDB(this.tableName, paginator); 
  }

  exportCsv(ds){
    const paginator = {page: 1, pagelements: 10, orderby:"", ordertype:"ASC"};
    this.filterService.manageGetDataDB(this.tableName, paginator, null, true);
  }

  reloadTable() {
    const paginator = new Paginator(1, this.table!=undefined?this.table.maxItems:10, 'pkId', 'ASC');
    if (this.table && this.table["columns"]) {

      paginator.orderby = this.table.columns[1].BBDD;
      
    }
    this.loadData(paginator);
  }
  getTable() {
    this.filterService.table$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.table = data;
      if (this.table && this.table["columns"] != null) {
        this.columnsToDisplay = [];
        this.showTable = false;
        this.displayedColumnsFunction();
      }
    });
  }

  initializeTranslate() {
    this.translation = this.translator.data;
    //esperamos 5 segundos para que estén las traducciones y revisamos si no hay para no mostrar columna
    let _that = this;
    setTimeout(function() { _that.displayedColumnsFunctionTrad() }, 5000);
  }
  
  displayedColumnsFunction() {
    this.table.columns.map(elem => {
      if (elem.columnActive != '' && elem.column) {
        this.showTable = true;
        this.columnsToDisplay.push(elem.name);
      }
    });
  }

  displayedColumnsFunctionTrad() {
    this.columnsToDisplay = [];
    if (this.table.columns) this.table.columns.map(elem => {
      if (elem.columnActive != '' && elem.column && this.hayTraduccion(elem.labelTranslate)) {
        this.showTable = true;
        this.columnsToDisplay.push(elem.name);
      }
    });
  }

  closepanel() {
/*     this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close => {
      this.customanpanel = undefined;
      this.closePanelChangeCss();

    }); */

  }
  closePanelChangeCss() {
    let classElem;

    classElem = document.getElementsByClassName('component');
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = 'block'

    }

  }

  updatePanel() {
/*     this.panelService.savePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(save => {
      this.reloadTable();
    }); */
  }



  duplicateRow(row) {
    this.alertaService.limpiar();
    this.customanMode = 'D';
    this.customanPkId = row.pkId;
    switch (this.tableName) {
      case 'operaciones':
/*     this.customanpanel = EditTipoCodigoPromocionesComponent;
    this.editChangeCss(); */
    break;
 
      default:
    break;
}

this.cdRef.detectChanges();

  }

  editRow(row) {
    this.alertaService.limpiar();
    this.customanMode = 'U';
    this.customanPkId = row.pkId;
    switch (this.tableName) {
      case 'operaciones':
/*         this.customanpanel = ProfileSaveComponent;
        this.editChangeCss(); */
        break;

      default:
        break;
    }



    // mat-sidenav-content  mat-drawer-content
    this.cdRef.detectChanges();

  }
  editChangeCss() {
    let classElem;
    classElem = document.getElementsByClassName('component');
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = 'none'
    }
  }


/*   //obtenemos minutos hasta la fecha/hora
  getMinutestoSession(fecha, hora) {
    var today = new Date();
    var lafechaStr = fecha;
    if (hora!='') lafechaStr +=" "+hora;
    var a = moment(today);
    if (hora!='') 
    var b = moment(lafechaStr,'DD/MM/YYYY HH:mm');
    else 
      var b = moment(lafechaStr,'DD/MM/YYYY');
    

    return b.diff(a, 'minutes');
  }

  //obtenemos minutos según tipo de tiempo y cantidad
  pasarAminutos(tipo, cantidad) {
    let minutos = 0;
    switch (tipo) {
      case "minutos":
        minutos = Number(cantidad);
      break;
      case "horas":
        minutos = Number(cantidad)*60;
      break;
      case "dias":
        minutos = Number(cantidad)*24*60;
      break;
      case "meses":
        minutos = Number(cantidad)*30*24*60;
      break;
      default:
        minutos = 0;
      break;
    }
    return minutos;
  } */
}

